.Topbar{
    height: 60px;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 3px 0px rgba(136,136,136,1);
    padding: 0 5%;
    z-index: 1;
}

.Topbar nav{
    height: 100%;
}

.Menubars{
    flex:0 0 23px;
    position: relative;
    margin-left: 20px;
    height: 100%;
    cursor: pointer;
}

.Menubars span{
    width: 100%;
    height: 2px;
    background: var(--primary-color);
    position: absolute;
} 

.Menubars span:nth-child(1){
    top: 20px;
}

.Menubars span:nth-child(2){
    top: 26px;
}

.Menubars span:nth-child(3){
    top: 33px;
}

.Rightpart{
    height: 100%;
    flex:1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.Rightpart > svg{
    fill:#4d726b;
    width: 15px;
    margin-right: 15px;
    font-size: 17px;
    color: #4d726b;
}

.LogoutWrapper{
    height: 100%;
    background: var(--primary-color);
    padding: 0 7px;
    display: flex;
    align-items: center;
    cursor:pointer;
}

.LogoutWrapper svg{
    fill:#fff;
    width: 15px;
    color: #fff;
    font-size: 17px;
}

.LoggedInName{
    margin-left: 5px;
    font-weight: 600;
    margin-right: 15px;
}

.Logo img{
    height: 60px;
}

.LogoContainer{
    margin-right:20px;
}


@media only screen and (max-width: 1000px) {
    .ConnectedAs{
        display: none;
    }
    .Rightpart > svg{
        display: none;
    }
}