.ScrollInner {
    padding: 20px;
    min-height: 100%;
    box-sizing: border-box;
}

.PageTitle {
    display: flex;
    align-items: center;
    padding: 20px;
    background: #fff;
    border-bottom: solid 1px var(--background-color);
    min-height: 40px;
}

.ActionButtonsWrapper {
    text-align: right;
    margin-left: auto;
    display: flex;
    align-items: center;
}
.ActionButtonsWrapper button {
    margin-right: 30px;
}

.PageTitle .CancelButton {
    margin-left: auto;
    cursor: pointer;
    background: var(--background-color);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PageTitle .CancelButton svg {
    font-size: 16px;
    color: var(--primary-color);
    transition: transform 0.2s ease-in;
}

.PageTitle .CancelButton:hover svg {
    transform: scale(1.1);
}

.PageContent {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.PageInner {
    flex: 1;
    min-height: 100%;
    box-sizing: border-box;
}

.Row {
    display: flex;
}

.Column {
    width: 48%;
}

.Separator {
    width: 4%;
}

.MarginBottom20 {
    margin-bottom: 20px;
}

.MarginBottom40 {
    margin-bottom: 40px;
}

.BlockItem {
    padding-bottom: 30px;
}

.GseImageWrapper {
    max-width: 400px;
}

.GseImageWrapper img {
    width: 100%;
}

.InfoList {
    margin-top: 10px;
}

.InfoList li,
.InfoList > div {
    line-height: 26px;
}

.ListTitle {
    color: var(--primary-color);
    font-weight: 700;
    margin-right: 10px;
}

.MapWrapper {
    height: calc(100% - 247px);
    width: 100%;
}

.MapWrapper > div {
    height: 100%;
}

.Flexed {
    display: flex;
}

.DatetimeWrapper {
    flex: 1;
}

.DatetimeWrapper > div {
    width: 100%;
}

.DatetimeWrapper:first-child {
    padding-right: 10px;
}

.DatetimeWrapper:last-child {
    padding-left: 10px;
}

.ButtonWrapper {
    margin-bottom: 20px;
}

.ConsumptionGroup {
    position: relative;
    width: 100%;
    max-width: 500px;
    border: solid 2px #e0e0e0;
    padding: 0 10px;
    margin-left: -10px;
    box-sizing: border-box;
}

.ConsumptionDiff {
    position: absolute;
    right: 10px;
    bottom: 0;
    color: var(--danger-color);
}

.BlockDivider {
    background: var(--background-color);
    padding: 20px;
    margin-left: -20px;
    width: 100%;
    margin-bottom: 30px;
}

.DatesWrapper {
    display: flex;
}

.ButtonsWrapper {
    display: flex;
    margin-left: 20px;
    margin-top: 0;
    width: 120px;
}

.ConsumptionButtonsWrapper {
    display: flex;
    margin-left: 20px;
    margin-top: 0;
    align-items: center;
}

.ChartWrapper {
    width: 100%;
    height: 300px;
    margin-top: 30px;
}

.AlertIcons {
    display: flex;
    align-items: center;
    overflow: auto;
}

.AlertType {
    position: relative;
    flex: 0 0 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #18222c;
    margin-right: 20px;
    cursor: pointer;
}

.AlertType.Selected {
    background: var(--primary-font-color);
}

.AlertType svg {
    width: 40px;
    fill: var(--primary-font-color);
}

.AlertType:first-child svg {
    width: 80px;
}

.AlertType.Selected svg {
    fill: #fff;
}

.AlertType > span {
    position: absolute;
    bottom: 15px;
    color: #18222c;
    font-weight: 700;
}

.AlertType:nth-child(2) {
    border-color: var(--danger-color);
}
.AlertType:nth-child(3) {
    border-color: #cb9a13;
}
.AlertType:nth-child(4) {
    border-color: #1da140;
}

.AlertResults {
    margin-top: 30px;
}

.TableActionsWrapper svg {
    padding: 5px;
    cursor: pointer;
    margin-right: 5px;
}
.TableActionsWrapper svg:last-child {
    margin-right: 0;
}

.DeleteIcon {
    color: var(--danger-color);
}
/* 
.ModalButtonsWrapper{
    text-align: right;
    margin-top: 20px;
    padding-top: 15px;
    border-top: solid 1px rgba(0, 0, 0, 0.23);
}
.ModalButtonsWrapper button{
    margin-left:10px;
} */

.ModalButtonsWrapper {
    border-bottom: solid 1px rgb(220, 226, 230);
    margin: -20px -20px 20px -20px;
    padding: 20px;
    display: flex;
    align-items: center;
}

.ModalButtonsWrapper .CancelButton {
    margin-left: auto;
    cursor: pointer;
    background: var(--background-color);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CancelButton svg {
    font-size: 16px;
    color: var(--primary-color);
    transition: transform 0.2s ease-in;
}
.CancelButton:hover svg {
    transform: scale(1.1);
}

.AddButtonWrapper {
    margin-bottom: 10px;
}

.MultipleButtonsWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.ResetButtons {
    display: flex;
    align-items: center;
    margin-left: 20px;
}
.ResetButtons button {
    margin-right: 5px;
}

.EditRowsWrapper {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
    margin-bottom: 0;
}

.EditRow {
    display: flex;
    align-items: center;
    margin-bottom: -20px;
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
}

.FileItem {
    display: flex;
    align-items: center;
}

.FileActions {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.FileItem svg {
    padding: 5px;
    cursor: pointer;
    margin-left: 5px;
}

.DatesContainer {
    display: flex;
    align-items: center;
}

.DisabledFieldsWrapper {
    display: flex;
    flex-wrap: wrap;
    background: var(--background-color);
    margin: -10px;
    margin-bottom: 0px;
    padding: 0 10px;
    padding-bottom: 10px;
}

.ResetsWrapper {
    display: flex;
    flex-direction: column;
}

.ResetRow {
    display: flex;
    align-items: center;
    margin-top: -15px;
}
.ResetRow > span {
    margin: 0 10px;
    display: inline-block;
}
.ResetRow > button {
    width: 205px;
}
.ResetInputWrapper {
    flex: 1;
}

.FiltersSpace {
    background: var(--background-color);
    flex: 0 0 64px;
    display: flex;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
}

.VerticalSpacer {
    padding: 30px 0;
}

.Timeframe {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 15px;
}
.Timeframe > div {
    background: #fff;
    text-align: center;
    padding: 10px 20px;
    cursor: pointer;
    border: solid 2px var(--background-color);
}
.Timeframe > div.TimeframeSelected {
    border-color: var(--secondary-color);
}

.Timeframe .Timeframe2 {
    padding: 0;
}

.Timeframe .Timeframe2 > div > div > div > div {
    padding: 10px 20px;
    box-sizing: border-box;
}

.Timeframe > div.CustomRange {
    padding: 0;
}
.Timeframe > div.CustomRange > div > div > div > div {
    padding: 10px 20px;
    box-sizing: border-box;
}

.TrackingTable {
    margin-top: 30px;
}
.TrackingRow {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    flex-wrap: wrap;
    margin-bottom: 30px;
}
.TrackingCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 200px;
}
.TrackingCol > span:first-child {
    font-weight: 700;
}
.TrackingCol > span:last-child {
    word-break: break-all;
    max-height: 250px;
    overflow: auto;
}

.NotificationsWrapper {
    margin-top: 20px;
}
.Notifications {
    margin-top: 50px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.NotificationsTitle {
    display: flex;
    align-items: center;
    transform: rotate(-90deg);
    width: 82px;
    margin-left: -20px;
}
.Notifications .NotificationsTitle svg {
    margin-right: 10px;
}
.Notifications h4 {
    margin: 0;
}
.Notifications.Errors .NotificationsTitle {
    color: var(--danger-color);
}
.Notifications.Warnings .NotificationsTitle {
    color: #cb9a13;
}
.Notifications.Updates .NotificationsTitle {
    color: #1382cb;
}

.DataTableWrapper {
    flex: 1;
}

.PrintPage {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
}

@media only screen and (max-width: 1000px) {
    .Row {
        flex-direction: column;
    }
    .Column {
        width: 100%;
    }

    .DatetimeWrapper > form,
    .DatetimeWrapper > form > div {
        width: 100%;
    }

    .ButtonWrapper {
        margin-top: 20px;
    }

    .MapWrapper {
        min-height: 400px;
    }

    .ButtonsWrapper {
        margin-left: 0;
        margin-top: 30px;
        width: 100%;
    }
}
