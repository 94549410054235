.PageTitle {
    display: flex;
    align-items: center;
    padding: 20px;
    background: #fff;
    border-bottom: solid 1px var(--background-color);
    min-height: 40px;
}

.PageContent {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.PageInner {
    flex: 1;
    min-height: 100%;
    box-sizing: border-box;
}

.ScrollInner {
    padding: 20px;
    min-height: 100%;
    box-sizing: border-box;
}

.FiltersSpace {
    background: var(--background-color);
    flex: 0 0 64px;
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 0;
    box-sizing: border-box;
}

.FiltersSpace > span {
    font-weight: 700;
    color: var(--primary-color);
}
.FiltersSpace > div {
    background: #fff;
    text-align: center;
    padding: 10px 20px;
    cursor: pointer;
    border: solid 2px var(--background-color);
}
.FiltersSpace > div.Selected {
    border: solid 2px var(--secondary-color);
}

.FiltersSpace > div.CustomRange {
    padding: 0;
}
.FiltersSpace > div.CustomRange > div > div > div > div {
    padding: 10px 20px;
    box-sizing: border-box;
}

.BlockItem {
    padding-bottom: 30px;
}

.AlertIcons {
    display: flex;
    align-items: center;
    overflow: auto;
}

.AlertType {
    position: relative;
    flex: 0 0 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #18222c;
    margin-right: 20px;
    cursor: pointer;
}

.AlertType.Selected {
    background: var(--primary-font-color);
}

.AlertType svg {
    width: 40px;
    fill: var(--primary-font-color);
}

.AlertType:first-child svg {
    width: 80px;
}

.AlertType.Selected svg {
    fill: #fff;
}

.AlertType > span {
    position: absolute;
    bottom: 15px;
    color: #18222c;
    font-weight: 700;
}

.AlertType:nth-child(2) {
    border-color: var(--danger-color);
}
.AlertType:nth-child(3) {
    border-color: #cb9a13;
}
.AlertType:nth-child(4) {
    border-color: #1da140;
}

.AlertResults {
    margin-top: 30px;
}

.GseName {
    width: 100px;
    display: inline-block;
    font-weight: 700;
    color: #18222c;
}
