.PrimaryDatetimePicker {
    width: 100%;
    max-width: 300px;
    position: relative;
}

.PrimaryDatetimePicker input {
    font-family: 'Noto Sans', sans-serif;
    color: var(--primary-color);
}

.PrimaryDatetimePicker button svg {
    color: var(--primary-color);
}

.FullWidth {
    max-width: 100%;
}

.Narrow {
    width: 170px;
}

.VeryNarrow {
    width: 70px;
}

.ClearIcon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
}

.ClearIconOnHover {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 45px;
    margin: auto;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
    font-size: 12px;
    border-radius: 50%;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.ClearIconOnHover:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

:local(.PrimaryDatetimePicker) :global(.react-datepicker__portal) {
    background-color: rgba(0, 0, 0, 0.5);
}

:local(.PrimaryDatetimePicker) :global(.react-datepicker) {
    padding: 20px;
    padding-bottom: 55px;
}

:local(.PrimaryDatetimePicker) :global(.react-datepicker__header) {
    background-color: #fff;
    border-bottom: 0;
    padding-top: 5px;
    padding-bottom: 0;
}

:local(.PrimaryDatetimePicker) :global(.react-datepicker__navigation) {
    top: 17px;
    border: solid 1px var(--primary-font-color);
}
:local(.PrimaryDatetimePicker) :global(.react-datepicker__navigation-icon:before) {
    width: 6px;
    height: 6px;
    border-width: 2px 2px 0 0;
    border-color: var(--primary-font-color);
}
:local(.PrimaryDatetimePicker) :global(.react-datepicker__navigation--previous) {
    left: 29px;
}
:local(.PrimaryDatetimePicker) :global(.react-datepicker__navigation--next) {
    left: 275px;
}
:local(.PrimaryDatetimePicker) :global(.react-datepicker__navigation--previous .react-datepicker__navigation-icon) {
    top: -2px;
    right: 1px;
}
:local(.PrimaryDatetimePicker) :global(.react-datepicker__navigation--next .react-datepicker__navigation-icon) {
    top: -2px;
    left: 1px;
}

:local(.PrimaryDatetimePicker) :global(.react-datepicker__month) {
    margin-top: 10px;
}

:local(.PrimaryDatetimePicker) :global(.react-datepicker__portal .react-datepicker__day) {
    font-family: var(--primary-font-family) !important;
    color: var(--primary-color);
    border: solid 1px #f0f0f0;
    border-radius: 0;
    margin: 0;
    border-radius: 0;
    margin-top: -1px;
    margin-left: -1px;
    width: 4rem;
    line-height: 4rem;
}

:local(.PrimaryDatetimePicker) :global(.react-datepicker__portal .react-datepicker__day--selected) {
    color: #fff;
}
:local(.PrimaryDatetimePicker) :global(.react-datepicker__portal .react-datepicker__day--in-range) {
    color: var(--primary-color);
    background-color: #bad9f1;
}
:local(.PrimaryDatetimePicker)
    :global(
        .react-datepicker__portal
            .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--in-range):not(.react-datepicker__day--selecting-range-start)
    ) {
    color: var(--primary-color);
    background-color: unset;
}
:local(.PrimaryDatetimePicker)
    :global(
        .react-datepicker__portal
            .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--in-range):not(.react-datepicker__day--selecting-range-start):hover
    ) {
    background-color: #f0f0f0;
}

:local(.PrimaryDatetimePicker) :global(.react-datepicker__portal .react-datepicker__day--in-selecting-range) {
    color: var(--primary-color);
    background-color: #bad9f1;
}
:local(.PrimaryDatetimePicker) :global(.react-datepicker__portal .react-datepicker__day--keyboard-selected) {
    color: #fff;
    background-color: #2579ba;
}
:local(.PrimaryDatetimePicker) :global(.react-datepicker__portal .react-datepicker__day--range-end) {
    color: #fff;
    background-color: #2579ba;
}
:local(.PrimaryDatetimePicker) :global(.react-datepicker__portal .react-datepicker__day--range-start) {
    color: #fff;
    background-color: #2579ba;
}

:local(.PrimaryDatetimePicker) :global(.react-datepicker__portal .react-datepicker__day-name) {
    color: var(--primary-font-color);
    font-size: var(--small-font-size);
    border: solid 1px transparent;
    margin: 0;
    margin-left: -1px;
    width: 4rem;
    line-height: 4rem;
}

:local(.PrimaryDatetimePicker) :global(.react-datepicker__current-month) {
    margin-bottom: 10px;
    margin-top: -2px;
    color: var(--primary-color);
}

:local(.PrimaryDatetimePicker) :global(.react-datepicker__portal .react-datepicker__day--outside-month) {
    border-color: transparent;
    visibility: hidden;
}

.CloseButton {
    position: absolute;
    bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.CloseButton > span {
    font-weight: 700;
    margin-left: 5px;
    margin-right: 5px;
}
.CloseButton > span:hover {
    color: var(--primary-color);
}

:local(.PrimaryDatetimePicker) :global(.react-datepicker__close-icon::after) {
    background-color: transparent;
    color: var(--primary-color);
    border-radius: 50%;
    width: 38px;
    height: 38px;
    font-size: 24px;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
:local(.PrimaryDatetimePicker) :global(.react-datepicker__close-icon:hover::after) {
    background-color: rgba(0, 0, 0, 0.04);
}

/* Time */
:local(.PrimaryDatetimePicker) :global(.react-datepicker__time-container) {
    width: 90px;
}
:local(.PrimaryDatetimePicker) :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box) {
    width: 90px;
}
:local(.PrimaryDatetimePicker) :global(.react-datepicker__header.react-datepicker__header--time) {
    padding-bottom: 5px;
}
:local(.PrimaryDatetimePicker) :global(.react-datepicker__time-list) {
    height: 258px !important;
}
:local(.PrimaryDatetimePicker) :global(.react-datepicker__time-list-item) {
    display: flex;
    align-items: center;
}
:local(.PrimaryDatetimePicker)
    :global(
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item
    ) {
    font-size: var(--small-font-size);
    margin: 0 2px;
}

:local(.PrimaryDatetimePicker) :global(.react-datepicker__day--disabled) {
    background: #fafafa;
    color: #aeb9c5 !important;
}
:local(.PrimaryDatetimePicker) :global(.react-datepicker__day--disabled:hover) {
    background: #fafafa;
    color: #aeb9c5 !important;
}
