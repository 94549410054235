.PageTitle {
    display: flex;
    align-items: center;
    padding: 20px;
    background: #fff;
    border-bottom: solid 1px var(--background-color);
}

.PageContent {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.PageInner {
    flex: 1;
    padding: 20px;
    min-height: 100%;
    box-sizing: border-box;
}

.MapWrapper {
    width: 100%;
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
}

.MapWrapper > div {
    height: 100%;
}

.PopupRow {
    margin-bottom: 3px;
}

.PopupRow span:first-child {
    color: var(--primary-color);
    font-weight: 700;
    margin-right: 10px;
}

.PopupButtonWrapper {
    border-top: solid 1px;
    margin-top: 15px;
    padding-top: 15px;
}

.RedCircle,
.GreenCircle,
.BlueCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    left: -1.5rem;
    top: 0rem;
    position: relative;
    border-radius: 50%;
}

.RedCircle svg,
.GreenCircle svg,
.BlueCircle svg {
    width: 10px;
    fill: #fff;
}

.RedCircle {
    background: rgba(255, 82, 82, 1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 2s infinite ease-in-out;
}
.GreenCircle {
    background: rgba(38, 133, 111, 1);
    box-shadow: 0 0 0 0 rgba(38, 133, 111, 1);
    animation: pulse-green 2s infinite ease-in-out;
}
.BlueCircle {
    background: rgba(65, 149, 228, 1);
    box-shadow: 0 0 0 0 rgba(65, 149, 228, 1);
    animation: pulse-blue 2s infinite ease-in-out;
}

.OptionsWrapper {
    margin-left: auto;
    margin-bottom: -2px;
}

.FiltersSpace {
    background: var(--background-color);
    flex: 0 0 64px;
    display: flex;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
}

.InputWrapper {
    display: flex;
    align-items: center;
    width: 211px;
    border-radius: 2px;
    padding: 10px;
    box-sizing: border-box;
    border: solid 1px var(--primary-font-color);
}

.InputWrapper > input {
    border: none;
    flex: 1;
}
.InputWrapper > input:focus-visible {
    outline: none;
}

.InputWrapper > input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--primary-font-color);
    opacity: 1; /* Firefox */
}
.InputWrapper > input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--primary-font-color);
}
.InputWrapper > input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--primary-font-color);
}

.InputWrapper > svg {
    padding-left: 10px;
    cursor: pointer;
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }
    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

@keyframes pulse-green {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(38, 133, 111, 0.5);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(38, 133, 111, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(38, 133, 111, 0);
    }
}

@keyframes pulse-blue {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(65, 149, 228, 0.5);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(65, 149, 228, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(65, 149, 228, 0);
    }
}

.LocationIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    left: -1.5rem;
    top: 0rem;
    position: relative;
    border-radius: 50%;
    background: rgb(118, 173, 218);
}

.LocationIcon svg {
    width: 14px;
    max-height: 14px;
    fill: #fff;
}
