.Modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.ModalInner {
    /* position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: white;
    z-index: 10001;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    overflow: auto;
    pointer-events: none;
}
.Show.Modal .ModalInner {
    opacity: 1;
    pointer-events: all;
}

.ModalInner .CloseWrapper {
    position: absolute;
    top: 6px;
    right: 10px;
    text-align: right;
    padding: 5px;
    cursor: pointer;
}

.Full .ModalInner {
    width: 90%;
    height: 90%;
    max-width: unset;
}

@media (min-width: 600px) {
    /* .Modal {
        width: 500px;
        left: calc(50% - 250px);
    } */
    .ModalInner {
        max-width: 600px;
        max-height: 90%;
        height: unset;
        border-radius: 4px;
    }
}
