.SideFilters {
    background: var(--background-color);
    position: relative;
}

.SideFiltersInner {
    width: 100%;
    max-width: 350px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 20px 24px;
    background: #fff;
    box-shadow: 2px 0 20px #505050;
    overflow-y: auto;
    z-index: 100000;
    opacity: 0;
    will-change: transform;
    transform: translateX(-100%);
    transition: transform 0.15s ease, opacity 0.15s ease;
}

.SideFilters.Show .SideFiltersInner {
    opacity: 1;
    transform: translateX(0);
}

.Title {
    display: flex;
    align-items: center;
}
.MarginTop20 {
    margin-top: 20px;
}

.Title h2 {
    flex: 1;
    font-size: 22px;
    line-height: 29px;
    display: inline-block;
    margin-right: 10px;
    color: var(--primary-color);
    -webkit-font-smoothing: auto;
}
.Title .CancelButton {
    margin-left: auto;
    cursor: pointer;
    background: var(--background-color);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Title .CancelButton svg {
    font-size: 16px;
    color: var(--primary-color);
    transition: transform 0.2s ease-in;
}
.Title .CancelButton:hover svg {
    transform: scale(1.1);
}

.FiltersRow {
    display: flex;
    align-items: flex-start;
}
.FiltersRow > button {
    min-width: 132px;
}
.FiltersRow > button,
.FiltersRow > button:hover {
    background-color: var(--primary-color);
}

.FiltersBoxes {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 20px;
    gap: 10px;
}

.FilterBox {
    background: #ddd;
    font-size: 14px;
    line-height: 18px;
    display: inline-block;
    position: relative;
    border-radius: 4px;
    padding: 0px 42px 0 10px;
    white-space: nowrap;
    height: 36.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.FilterBox:hover {
    background: #d2d2d2;
}

.FilterBox > span {
    color: var(--primary-color);
}

.FilterBox .FilterBoxIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0;
    width: 30px;
    border-left: 1px solid var(--primary-color);
    height: 65%;
}
.FilterBox .FilterBoxIcon svg {
    color: var(--primary-color);
}

.LocationTypeIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    margin-right: 10px;
    border-radius: 50%;
    background: rgb(118, 173, 218);
}

.LocationTypeIcon svg {
    width: 14px;
    max-height: 14px;
    fill: #fff;
}

.StartingTypeIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    margin-right: 10px;
    border-radius: 50%;
}
.StartingTypeIcon svg {
    width: 14px;
    max-height: 14px;
    fill: #fff;
}

.EndingTypeIcon {
    width: 3rem;
    max-height: 30px;
    margin-right: 10px;
}

.TripTypeIcon {
    width: 30px;
    margin-right: 10px;
    border-bottom: solid 1px;
}

.SvgTypeIcon {
    display: flex;
}

.SvgTypeIcon svg {
    width: 19px;
    margin-right: 10px;
}
