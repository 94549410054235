.FormControl{
    width: 100%;
    max-width: 300px;
    margin:15px 0!important;
    background-color: #fff;
}

.FormControl div{
    font-family: 'Noto Sans', sans-serif;
    color: var(--primary-color);
}

.FormControl.Narrow{
    width: 170px;
}

.FormControl.VeryNarrow{
    width: 70px;
}

.FullWidth{
    max-width:100%;
}