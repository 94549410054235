.PageTitle {
    display: flex;
    align-items: center;
    padding: 20px;
    background: #fff;
    border-bottom: solid 1px var(--background-color);
}

.PageContent {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.PageInner {
    flex: 1;
    padding: 20px;
    min-height: 100%;
    box-sizing: border-box;
}

.Row {
    display: flex;
}

.Column {
    width: 48%;
}

.Column > div > div > div {
    margin: 0 !important;
}

.Separator {
    width: 4%;
}
.VerticalSpacer {
    margin-bottom: 3px;
}

.BlockItem {
    padding-bottom: 30px;
}

.MapWrapper {
    height: 500px;
    width: 100%;
}

.MapWrapper > div {
    height: 100%;
}

.Flexed {
    display: flex;
}

.DatetimeWrapper {
    flex: 1;
}

.DatetimeWrapper > div {
    width: 100%;
}

.DatetimeWrapper:first-child {
    padding-right: 10px;
}

.DatetimeWrapper:last-child {
    padding-left: 10px;
}

.DatetimeWrapper label {
    font-size: inherit;
    font-family: inherit;
}
.DatetimeWrapper svg {
    width: 1.2em !important;
    height: 1.2em !important;
}
.DatetimeWrapper p,
.DatetimeWrapper span {
    font-size: inherit;
    font-family: inherit;
}

.ButtonWrapper {
    margin-bottom: 20px;
}

.FiltersSpace {
    background: var(--background-color);
    flex: 0 0 64px;
    display: flex;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
}

.SliderWrapper {
    margin-bottom: 20px;
    margin-top: 40px;
}
.SliderWrapper > h4 {
    margin-bottom: 0;
    color: var(--primary-color);
}
.SliderBar {
    display: flex;
    align-items: center;
}
.SliderWrapper svg {
    margin-right: 20px;
    cursor: pointer;
    padding: 5px;
    border: solid 1px var(--primary-font-color);
}

.PopupRow {
    margin-bottom: 3px;
}

.PopupRow span:first-child {
    color: var(--primary-color);
    font-weight: 700;
    margin-right: 10px;
}

.DefaultColor svg,
.RedColor svg {
    width: 18px;
}

.DefaultColor svg {
    fill: #404b58;
}
.RedColor {
    fill: red;
}

.BlueColor svg,
.GreenColor svg {
    width: 28px;
}
.BlueColor {
    fill: #4f93cf;
}
.GreenColor {
    fill: #2aae65;
}

.PopupButtonWrapper {
    border-top: solid 1px;
    margin-top: 15px;
    padding-top: 15px;
}

.TripsTable {
    margin: 40px 0;
}

@media only screen and (max-width: 1000px) {
    .Row {
        flex-direction: column;
    }
    .Column {
        width: 100%;
    }

    .DatetimeWrapper > form,
    .DatetimeWrapper > form > div {
        width: 100%;
    }

    .MapWrapper {
        min-height: 400px;
    }
}
