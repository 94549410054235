.Topbar{
    height: 60px;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 3px 0px rgba(136,136,136,1);
    padding: 0 15px;
    z-index: 0;
}
.Topbar.Show{
    z-index: 2;
}

.Topbar nav{
    height: 100%;
}

.MenuToggle{
    width: 30px;
    height: 30px;
    border: solid 1px var(--primary-font-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.SidenavWrapper{
    background: var(--background-color);
    position: relative;
}

.Sidenav{
    width: 100%;
    max-width: 350px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 20px 24px;
    background: #fff;
    box-shadow: 2px 0 20px #505050;
    overflow-y: auto;
    z-index: 100000;
    opacity: 0;
    will-change: transform;
    transform: translateX(-100%);
    transition: transform .15s ease, opacity .15s ease;
}

.Topbar.Show .SidenavWrapper .Sidenav{
    opacity: 1;
    transform: translateX(0);
}

.Title{
    display: flex;
    align-items: center;
}

.Title h2{
    flex:1;
    font-size: 22px;
    line-height: 29px;
    display: inline-block;
    margin-right: 10px;
    color: var(--primary-color);
    -webkit-font-smoothing: auto;
}
.Title .CancelButton{
    margin-left: auto;
    cursor: pointer;
    background: var(--background-color);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Title .CancelButton svg{
    font-size: 16px;
    color: var(--primary-color);
    transition: transform 0.2s ease-in;
}
.Title .CancelButton:hover svg{
    transform: scale(1.1);
}


.Rightpart{
    height: 100%;
    flex:1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.Rightpart > svg{
    fill:#4d726b;
    width: 15px;
    margin-right: 15px;
    font-size: 17px;
    color: #4d726b;
}

.LogoutWrapper{
    height: 100%;
    background: var(--primary-color);
    padding: 0 7px;
    display: flex;
    align-items: center;
    cursor:pointer;
}

.LogoutWrapper svg{
    fill:#fff;
    width: 15px;
    color: #fff;
    font-size: 17px;
}

.LoggedInName{
    margin-left: 5px;
    font-weight: 600;
    margin-right: 15px;
}

.Logo img{
    height: 60px;
}

.LogoContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:40px;
}
