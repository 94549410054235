.Block{
    width:100%;
    overflow: hidden;
}
.Hide.Block .BlockContent{
    height:0;
}
.Hide.Block .SvgWrapper svg{
    transform: rotate(180deg);
}

.TitleWrapper{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    border-bottom:solid 1px var(--block-color);
}

.TitleWrapper h4{
    color: var(--block-color);
    flex:1;
    margin-bottom: 10px;
    margin-top: 0;
}

.SvgWrapper{
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 12px;
    border-radius: 50%;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.SvgWrapper:hover{
    background-color: rgba(0, 0, 0, 0.04);
}
