
.SelectFieldsWrapper{
    position: relative;
    background:#E4E9EF;
}

.SelectFieldsWrapper > button,
.SelectFieldsWrapper > button:hover{
    background-color: #404B58;
}

.FieldsWrapper{
    opacity:0;
    /* background: #F9FAFC; */
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
    position: absolute;
    left: 0;
    top: 36px;
    z-index: 100;
    pointer-events: none;
    /* box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12); */
    box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 30px -10px, rgba(0, 0, 0, 0.07) 0px 0px 0px 1px;
}

.FieldsWrapper div,
.FieldsWrapper span,
.FieldsWrapper label,
.FieldsWrapper input{
    font-size: inherit!important;
    font-family: inherit!important;
}

.FieldsWrapper svg{
    width:20px!important;
    height:20px!important;
}


.SelectAllWrapper{  
    border-bottom: solid 1px #ECF0F4;
    margin-bottom: 10px;
    padding-bottom: 6px;
    margin-top: -10px;
}

.SelectAllWrapper label{
    margin-left:0;
}

.FieldsWrapperUl{
    list-style: none;
    padding: 0 20px;
    margin-left: -20px;
    overflow: auto;
    max-height: 100%;
    min-width: 165px;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 5px;
}

.FieldsWrapperUl li{
    margin-top:-5px;
}

.ShowFields .FieldsWrapper{
    opacity:1;
    animation:fadeInDown 1s ease-out forwards;
    animation-iteration-count:1;
    pointer-events: all;

    /* Safari and Chrome */
    -webkit-animation:fadeInDown 1s;
    -webkit-animation-iteration-count:1;
}

@keyframes fadeInDown
{ 
    from {top:0px; opacity: 0;}
    to {top:36px; opacity: 1}
}
@-webkit-keyframes fadeInDown /* Safari and Chrome */
{
    from {top:0px; opacity: 0;}
    to {top:36px; opacity: 1;}
}
