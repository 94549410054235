.NavigationItem {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%;
    white-space: nowrap;
}

.NavigationItem a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    color: #333;
    text-decoration: none;
    box-sizing: border-box;
    padding: 10px;
}

.NavigationItem a::after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    border-bottom: 2px solid var(--secondary-color);
}

.NavigationItem a,
.NavigationItem a::after {
    transition: left 0.2s ease-in, width 0.2s ease-in, color 0.2s ease-in;
}

.NavigationItem a:hover,
.NavigationItem a.active {
    color: var(--secondary-color);
}

.NavigationItem a:hover::after,
.NavigationItem a.active::after {
    left: 0;
    width: 100%;
}

.NavigationItem a span {
    transition: color 0.2s ease-in;
}

.NavigationItem a:hover span,
.NavigationItem a.active span {
    color: var(--secondary-color);
}

@media (max-width: 1200px) {
    .NavigationItem a:hover::after,
    .NavigationItem a.active::after {
        width: 0;
    }
    .NavigationItem a {
        padding-left: 0;
    }
}
