.Logo img {
    height:30px;
}

/* .Closed.Logo{
    height: 50px;
} */

/* .Closed img{
    display: none;
} */