.FilesList{
}

.UploadItemsWrapper{
    margin-bottom:5px;
}

.FileItem{
    display: flex;
    align-items: center;
}

.FileActions{
    margin-left:auto;
}

.FileItem svg{
    padding: 5px;
    cursor: pointer;
    margin-left: 5px;
}

.FileProgressBarBackground{
    width: 100%;
    height: 3px;
    border-radius: 5px;
    background: #e6e6e6;
    position: relative;
}

.FileProgressBar{
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 3px;
    border-radius: 3px;
    background: var(--secondary-color);
    transition:width 0.2s ease-in;
}

.UploadFilesTitle{
    margin-bottom: 7px;
    font-size: var(--normal-font-size);
}


.DeleteIcon{
    color:var(--danger-color);
}
