.Datatable > div {
    box-shadow: none !important;
}

.searchAbs > div > div:first-child {
    top: -72px;
    right: -20px;
    padding: 0;
    position: absolute;
    margin-right: 5px;
}

.searchRel > div > div:first-child {
    /* margin-bottom: 15px; */
}

.DetailPanel {
    background: var(--background-color);
    padding: 20px;
    box-sizing: border-box;
}

.DetailPanel span {
    color: var(--primary-color);
}

.DetailPanelTitle {
    display: block;
    font-weight: 700;
    margin-bottom: 20px;
}
