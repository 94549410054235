.PageTitle {
    display: flex;
    align-items: center;
    padding: 20px;
    background: #fff;
    border-bottom: solid 1px var(--background-color);
    min-height: 40px;
}

.PageContent {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.PageInner {
    flex: 1;
    min-height: 100%;
    box-sizing: border-box;
}

.ScrollInner {
    padding: 20px;
    min-height: 100%;
    box-sizing: border-box;
}

.FiltersSpace {
    background: var(--background-color);
    flex: 0 0 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    flex-wrap: wrap;
    gap: 10px;
}

.MarginLeftAuto {
    margin-left: auto;
}

.ActionButtonsWrapper {
    text-align: right;
    margin-left: auto;
    display: flex;
    align-items: center;
}
.ActionButtonsWrapper button {
    margin-right: 30px;
}

.PageTitle .CancelButton {
    margin-left: auto;
    cursor: pointer;
    background: var(--background-color);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PageTitle .CancelButton svg {
    font-size: 16px;
    color: var(--primary-color);
    transition: transform 0.2s ease-in;
}

.PageTitle .CancelButton:hover svg {
    transform: scale(1.1);
}

.FilterName {
    font-weight: 700;
    margin-right: 10px;
    color: var(--primary-color);
}

.BlockItem {
    padding-bottom: 30px;
}

.VerticalSpacer {
    padding: 30px 0;
}
.HorizontalSpacer {
    margin: 0 3px;
}
/* 
.PieChartWrapper {
    position: relative;
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
} */
