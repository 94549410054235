.PrintId {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 12px;
    border-radius: 50%;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.PrintId svg {
    font-size: 15px;
    color: var(--primary-color);
}
