.Footer{
    display: flex;
    align-items: center;
    padding: 10px 0;
}

.Footer span{
    font-size: var(--small-font-size);
    margin-left: auto;
}

.Footer img{
    height: 50px;
}


.FooterLogos_part{
    display: inline-block;
    margin: 10px 20px;
}

.FooterLogos_part img{
    max-height: 55px;
}

