.Wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.ContentWrapper {
    background: var(--background-color);
    position: relative;
    /* overflow-x: hidden; */
    display: flex;
    flex-direction: column;

    flex: 1;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 1;
}

.Content {
    position: relative;
    padding: 0 5%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.SidebarClosed .SideBarWrapper {
    width: 0;
    overflow: hidden;
}

.SideBarWrapper {
    width: 280px;
    height: 100%;
    position: relative;
    z-index: 100;

    transition-property: width;
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.NotLoggedIn .SideBarWrapper {
    display: none;
}

.Strip {
    width: 100%;
    flex: 0 0 5px;
    background: var(--primary-color);
}

.NoStrip {
    flex: 1;
    display: flex;
    width: 100%;
    height: 100%;
}

.SecondColumn {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    z-index: 1;
    min-width: 0;
}

.Content_inner {
    flex: 1;
    background: #fff;
    display: flex;
    flex-direction: column;
}

.Curtain {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;

    transition: opacity 0.3s ease-in;
}

.CloseSidebarButtonWrapper {
    display: none;
    position: absolute;
    right: -40px;
    top: 8px;
}

@media only screen and (max-width: 1200px) {
    .Content {
        padding: 0 15px;
    }
    .Curtain {
        opacity: 1;
        pointer-events: all;
    }
    .SideBarWrapper {
        max-width: 80%;
        position: absolute;
    }
    .SidebarClosed .SideBarWrapper {
        width: 0;
        overflow: hidden;
    }
    .SidebarClosed .Curtain,
    .NotLoggedIn .Curtain {
        opacity: 0;
        pointer-events: none;
    }
    .CloseSidebarButtonWrapper {
        display: block;
    }
}
