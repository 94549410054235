.FormControl {
  width: 100%;
  max-width: 300px;
  margin: 15px 0 !important;
  background-color: #fff;
  border-radius: 4px;
}

.FormControl input {
  font-family: 'Noto Sans', sans-serif !important;
  color: var(--primary-color) !important;
}

.FormControl.Narrow {
  width: 170px;
}

.FormControl.VeryNarrow {
  width: 70px;
}

.FullWidth {
  max-width: 100%;
}

.FormControl.Short {
  margin: 0 !important;
}

.FormControl.Short input {
  height: 4px;
}
