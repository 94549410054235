.main-theme {
    --primary-color: #404b58;
    --primary-font-color: #8d9cab;
    --primary-font-family: 'Open Sans', sans-serif;

    --secondary-color: #008f40;
    --block-color: #1382cb;

    --background-color: #e4e9ef;
    --danger-color: #ee3224;
    --disabled-color: #6c757d;

    --large-font-size: 3rem;
    --normal-font-size: 1.4rem;
    --small-font-size: 1.2rem;
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
}

html {
    font-size: 62.5%;
}

body {
    overflow: hidden;
}

h1 {
    font-weight: 600;
    margin: 0;
    color: var(--primary-color);
    font-size: var(--large-font-size);
}

p,
span,
div,
input {
    font-weight: 400;
    color: var(--primary-font-color);
    font-size: 1.4rem;
    font-family: 'Open Sans', sans-serif;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-indent: 0;
}

/* Material UI */
.MuiButtonBase-root,
.MuiTypography-body1,
.MuiFormLabel-root,
.MuiInputBase-root {
    font-size: inherit !important;
    font-family: inherit !important;
    color: var(--primary-font-color) !important;
}

.MuiAutocomplete-popper li {
    font-size: 14px !important;
    font-family: 'Open Sans', sans-serif;
    color: var(--primary-font-color) !important;
}

.MuiTypography-caption,
.MuiTypography-body2,
.MuiFormHelperText-root.Mui-error {
    font-size: inherit !important;
    font-family: inherit !important;
}

.MuiFormHelperText-root.Mui-error {
    font-size: 1.2rem !important;
    font-family: inherit !important;
}

.MuiChip-label {
    color: var(--primary-color);
}

.MuiSvgIcon-root {
    width: 20px !important;
    height: 20px !important;
}

.MuiPickersDay-daySelected,
.MuiPickersClockNumber-clockNumberSelected {
    color: #fff !important;
}

.MuiPickersDay-day.MuiPickersDay-dayDisabled {
    color: rgba(0, 0, 0, 0.38) !important;
}

.MuiToolbar-root {
    min-height: 0 !important;
    padding: 0 !important;
}

.Mui-disabled {
    background: var(--background-color);
}

.MuiListItem-root {
    min-height: 33px !important;
}

.MuiInputBase-adornedStart {
    padding-left: 0 !important;
}

.MuiPickersYear-root,
.MuiPickersMonth-root {
    font-size: 1.4rem !important;
}

.MuiPickersYear-yearSelected {
    font-size: 3rem !important;
}

.MuiPickersMonth-monthSelected {
    font-size: 2.3rem !important;
}

.MuiPickersToolbarText-toolbarTxt.MuiTypography-subtitle1 {
    font-size: 1.4rem !important;
}

.MuiTypography-h4 {
    font-size: 3rem !important;
}

.MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: -18px;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone > div {
    justify-content: center;
}

.MuiPickersDatePickerRoot-toolbar {
    align-items: unset !important;
}

/* Color picker */
.ColorPicker-MuiInputBase-input {
    font-weight: 400 !important;
    color: var(--primary-font-color) !important;
    font-size: var(--normal-font-size) !important;
    font-family: var(--primary-font-family) !important;
}
.muicc-colorbox-sliders {
    box-sizing: border-box;
}

/* Search bar */
.searchAbs .MuiFormControl-root.MuiTextField-root {
    margin-right: 5px;
}
.searchAbs .MuiPaper-root > .MuiToolbar-root.MuiToolbar-gutters {
    top: -72px;
    right: -20px;
    padding: 0;
    position: absolute;
    margin-right: 5px;
}

/* dropzone */
.MuiDropzoneArea-root {
    min-height: unset !important;
}
.MuiDropzoneArea-text {
    font-size: 1.6rem !important;
}
.MuiDropzoneArea-icon {
    display: none !important;
}

.MuiDropzoneSnackbar-message {
    color: #fff !important;
}
.MuiDropzoneSnackbar-icon {
    fill: #fff !important;
}

/* tabs */
.react-tabs {
    -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 10px;
    padding: 0;
    height: 64px;
    background: var(--background-color);
}

.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    min-width: 156px;
}

.react-tabs__tab--selected {
    background: #fff;
    /* border-color: #aaa;
  color: black; */
}

.react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
}

.react-tabs__tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    border-color: hsl(208, 99%, 50%);
    outline: none;
}

.react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
}

.react-tabs__tab-panel {
    display: none;
}

.react-tabs__tab-panel--selected {
    display: block;
}

/* Additions */
.react-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.react-tabs__tab-list {
    display: flex;
    margin-bottom: 0;
    border-bottom: none;
    height: auto;
    overflow: auto;
}

.react-tabs__tab > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-tabs__tab {
    width: 156px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-right: 1px solid var(--primary-color);
    color: var(--primary-color);
    bottom: 0;
    padding: 12px;
    box-sizing: border-box;
}

.react-tabs__tab:focus {
    outline: none;
    box-shadow: none;
    border-color: unset;
}

.react-tabs__tab:focus:after {
    content: none;
}

.react-tabs__tab svg {
    fill: var(--primary-color);
    width: 32px;
    margin-right: 18px;
    font-size: 26px;
}

.react-tabs__tab--selected {
    background: var(--primary-color);
    color: #fff;
}

.react-tabs__tab--selected svg {
    fill: #fff;
}

.react-tabs__tab--disabled {
    cursor: not-allowed;
    opacity: 0.4;
}

.react-tabs__tab-panel--selected {
    flex: 1;
    display: flex;
}

@media only screen and (max-width: 1000px) {
    .react-tabs__tab {
        flex: 0 0 122px;
        flex-direction: column;
        padding: 0;
    }
    .react-tabs__tab:last-child {
        border-right: 0;
    }

    .react-tabs__tab svg {
        margin-right: 0;
    }
}

/* Recharts */
.recharts-surface {
    overflow: visible;
}

/* Leaflet */
.leaflet-container {
    z-index: 0;
}
