.PageTitle {
    display: flex;
    align-items: center;
    padding: 20px;
    background: #fff;
    border-bottom: solid 1px var(--background-color);
}

.PageContent {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.PageInner {
    flex: 1;
    padding: 20px;
    min-height: 100%;
    box-sizing: border-box;
}

.goToGseIcon svg {
    width: 20px;
    padding: 4px;
    cursor: pointer;
    fill: var(--primary-font-color);
}

.TableActionsWrapper svg {
    padding: 5px;
    cursor: pointer;
    margin-right: 5px;
}
.TableActionsWrapper svg:last-child {
    margin-right: 0;
}

.DeleteIcon {
    color: var(--danger-color);
}

.MarginLeftAuto {
    margin-left: auto;
}

.FiltersSpace {
    background: var(--background-color);
    flex: 0 0 64px;
    display: flex;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
}
