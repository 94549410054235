.ChartWrapper {
    position: relative;
    width: 100%;
    height: 400px;
}

.PeriodSelection {
    display: flex;
    align-items: center;
    border-top: solid 1px #d5d5d5;
    margin-bottom: 40px;
}

.Year {
    font-weight: 700;
    padding: 3px 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.MonthButton,
.YearButton,
.SumButton {
    padding: 0 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    position: relative;
    transition: color 0.3s ease-out;
}

.MonthButton {
    margin-left: auto;
}

.SumButton,
.YearButton {
    border-left: solid 1px #d5d5d5;
}

.Active.MonthButton,
.Active.YearButton,
.Active.SumButton {
    font-weight: 700;
    color: var(--primary-color);
}

.Active.MonthButton::after,
.Active.YearButton::after,
.Active.SumButton::after {
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    bottom: -10px;
    left: 0;
    background-color: var(--primary-color);
}

.YearSelection {
    flex: 0 0 50%;
    padding-left: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.YearSelection .SvgWrapper > svg {
    font-size: 2rem;
    color: var(--primary-color);
    transition: transform 0.2s ease-in;
}

.YearSelection > span {
    font-weight: 700;
    padding: 0 50px;
    color: var(--primary-color);
}

.YearSelection .SvgWrapper {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--background-color);
}

.YearSelection .SvgWrapper:hover > svg {
    transform: scale(1.1);
}

.Placeholder {
    width: 30px;
}

.StatsProperty > span:first-child {
    color: var(--primary-color);
    font-weight: 700;
    margin-right: 10px;
}

.ExportToPngWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ExportToPngWrapper > span {
    margin-right: 5px;
}
