.AuthenticatedLink{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AuthenticatedLinkButton{
    background-color:var(--primary-color);
    padding: 15px 22px;
    border: 0;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    outline: none!important;
    
    box-shadow: 4px 4px 18px 0px rgba(2, 60, 142, 0.3);
    transition:background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.AuthenticatedLinkButton:hover{
    background-color:var(--secondary-color);
    box-shadow: 4px 4px 18px 0px rgba(249, 174, 23, 0.3);
}

.AuthenticatedLinkButton svg{
    margin-right: 10px;
}

.AuthenticatedLinkButton span{
    color: #fff;
}