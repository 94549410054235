.SideFilter{
    border-bottom:solid 1px var(--background-color);
}

.Header{
    display: flex;
    align-items: center;
    padding-right: 10px;
    cursor: pointer;
}

.Header h4{
    flex: 1;
    font-size: 16px;
    font-weight: 400;
    user-select: none;
}

.Content{
    display: block;
    height:auto;
}
.Content.Hide{
    display: none;
}

.MiniContent{
    display: none;
    margin-top: -10px;
}
.MiniContent.Show{
    display: block;
    padding-bottom: 16px;
}
.MiniContent.Empty{
    display: none;
}
.MiniContent > span{
    opacity: 0.7;
    margin-right: 6px;
}

.ContentInner{
    padding-bottom: 16px;
}

.Multilist.FilterOptions{
    margin-right:-5px;
}

.Multilist.FilterOptions .FilterOption{
    display: flex;
    align-items: center;
}

.Multilist.FilterOptions .FilterOption .FilterOptionLabel{
    flex:1;
    margin: 5px 0;
}

.Multilist.FilterOptions .FilterOption > label{
    margin:0;
}

.FilterOptionAll{
    display: flex;
    align-items: center;
    margin-right: -5px;
}
.FilterOptionAll .FilterOptionLabel{
    flex:1;
    margin: 5px 0;
}
.FilterOptionAll > label{
    margin:0;
}


.OptionsMoreHeader{
    color: var(--block-color);
    display: inline-block;
}
.OptionsMoreHeader > span{
    display: inline-block;
    padding: 0 8px 0 0;
    margin-top: 10px;
    color: var(--block-color);
    cursor: pointer;
}
.OptionsMoreHeader > svg{
    cursor: pointer;
}

.OptionsMoreContent{
    display: none;
}
.OptionsMoreContent.Show{
    display: block;
}

.OptionsMoreWrapper .FilterOptions{
    max-height: 400px;
    overflow-y: auto;
}

.OptionsMoreContent .InputWrapper{
    display: flex;
    align-items: center;
    margin: 16px auto 8px;
    width: 100%;
    border-radius: 2px;
    padding: 10px;
    box-sizing: border-box;
    border: solid 1px var(--primary-font-color);
}

.OptionsMoreContent .InputWrapper > input{
    border:none;
    flex: 1;
}
.OptionsMoreContent .InputWrapper > input:focus-visible{
    outline:none;
}

.OptionsMoreContent .InputWrapper > input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--primary-font-color);
    opacity: 1; /* Firefox */
}
.OptionsMoreContent .InputWrapper > input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--primary-font-color);
}
.OptionsMoreContent .InputWrapper > input::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--primary-font-color);
}

.OptionsMoreContent .InputWrapper > svg{
    padding-left: 10px;
    cursor:pointer;
}



.Radio.FilterOptions{
    margin-right:-5px;
}

.Radio.FilterOptions .FilterOption{
    display: flex;
    align-items: center;
}

.Radio.FilterOptions .FilterOption .FilterOptionLabel{
    flex:1;
    margin: 5px 0;
}

.Radio.FilterOptions .FilterOption > label{
    margin:0;
}