.PageTitle {
    display: flex;
    align-items: center;
    padding: 20px;
    background: #fff;
    border-bottom: solid 1px var(--background-color);
    min-height: 40px;
}

.PageContent {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.PageInner {
    flex: 1;
    min-height: 100%;
    box-sizing: border-box;
}

.ScrollInner {
    padding: 20px;
    min-height: 100%;
    box-sizing: border-box;
}

.FiltersSpace {
    background: var(--background-color);
    flex: 0 0 64px;
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 0;
    box-sizing: border-box;
}

.FiltersSpace > span {
    font-weight: 700;
    margin-right: 10px;
    color: var(--primary-color);
}
.FiltersSpace .Timeframe {
    background: #fff;
    text-align: center;
    padding: 0;
    cursor: pointer;
    border: solid 2px var(--background-color);
}
.FiltersSpace .Timeframe.Selected {
    border: solid 2px var(--secondary-color);
}

.FiltersSpace .Timeframe > div > div > div > div {
    padding: 10px 20px;
    box-sizing: border-box;
}

.EquipmentSelect {
    display: flex;
    align-items: center;
}
.EquipmentSelect > span {
    font-weight: 700;
    margin-right: 10px;
    color: var(--primary-color);
}

.HorizontalSpacer {
    margin: 0 3px;
}
.VerticalSpacer {
    padding: 30px 0;
}
