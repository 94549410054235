.PageTitle {
    display: flex;
    align-items: center;
    padding: 20px;
    background: #fff;
    border-bottom: solid 1px var(--background-color);
    min-height: 40px;
}

.PageContent {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.PageInner {
    flex: 1;
    min-height: 100%;
    box-sizing: border-box;
}

.ScrollInner {
    padding: 20px;
    box-sizing: border-box;
}

.ActionButtonsWrapper {
    text-align: right;
    margin-left: auto;
    display: flex;
    align-items: center;
}
.ActionButtonsWrapper button {
    margin-right: 30px;
}

.PageTitle .CancelButton {
    margin-left: auto;
    cursor: pointer;
    background: var(--background-color);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PageTitle .CancelButton svg {
    font-size: 16px;
    color: var(--primary-color);
    transition: transform 0.2s ease-in;
}

.PageTitle .CancelButton:hover svg {
    transform: scale(1.1);
}

.MarginLeftAuto {
    margin-left: auto;
}
.MarginBottom10 {
    margin-bottom: 10px;
}

.AdminBoxWrapper {
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.2s ease-in;
}
.AdminBox {
    width: 120px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid 1px;
    padding: 20px;
}
.AdminBox > svg {
    font-size: 50px;
    color: var(--primary-color);
    fill: var(--primary-color);
    flex: 1;
    max-width: 50px;
}
.AdminBox > span {
    font-weight: 600;
    color: var(--primary-color);
    margin-top: auto;
}
.AdminBoxWrapper:hover {
    background-color: var(--background-color);
}

.InfoList {
    margin-top: 10px;
}

.InfoList li,
.InfoList > div {
    line-height: 26px;
}

.ListTitle {
    color: var(--primary-color);
    font-weight: 700;
    margin-right: 10px;
}

.TableActionsWrapper svg {
    padding: 5px;
    cursor: pointer;
    margin-right: 5px;
}
.TableActionsWrapper svg:last-child {
    margin-right: 0;
}
.DeleteIcon {
    color: var(--danger-color);
}
.Disabled.DeleteIcon {
    pointer-events: none;
    color: #ddd;
}

.ModalButtonsWrapper {
    border-bottom: solid 1px rgb(220, 226, 230);
    margin: -20px -20px 20px -20px;
    padding: 20px;
    display: flex;
    align-items: center;
}

.ModalButtonsWrapper .CancelButton {
    margin-left: auto;
    cursor: pointer;
    background: var(--background-color);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CancelButton svg {
    font-size: 16px;
    color: var(--primary-color);
    transition: transform 0.2s ease-in;
}
.CancelButton:hover svg {
    transform: scale(1.1);
}

.EditRowsWrapper {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
    margin-bottom: 0;
}

.EditRow {
    display: flex;
    align-items: center;
    margin-bottom: -20px;
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
}
.EditRow.FullRow {
    width: 100%;
}
.EditRow.FullRow > div {
    max-width: unset;
}
/* 
.EditRow > span{
    flex: 0 0 170px;
} */

.RandomPasswordWrapper {
    width: 100%;
    display: flex;
    align-items: center;
}

.RandomPassword {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 53px;
    border: solid 1px rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
}

.ButtonsWrapper {
    display: flex;
    align-items: center;
}

.Tab {
    background-color: #fff;
    padding: 15px 22px;
    border: solid 1px var(--primary-font-color);
    color: var(--primary-font-color);
    cursor: pointer;
    margin-right: 10px;

    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.Active.Tab {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    color: #fff;
}

.TabsPanels {
    margin-top: 40px;
}

.TabPanel {
    display: none;
}

.TabPanel.ActivePanel {
    display: block;
}

.ActionsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ActionButton {
    padding: 0 10px;
    cursor: pointer;
}
.DeleteButton.ActionButton {
    color: var(--danger-color);
}

.ActionButton.Disabled {
    pointer-events: none;
    color: #ddd;
}

.Table {
    display: table;
    width: 100%;
}
.Row {
    display: table-row;
}
.Cell {
    width: 170px;
    display: table-cell;
    padding-bottom: 50px;
    padding-right: 10px;
    vertical-align: middle;
}
.ResultCell {
    display: table-cell;
    padding-bottom: 50px;
    font-weight: 700;
    vertical-align: middle;
}

.CellNoPadding {
    width: 170px;
    display: table-cell;
    padding-right: 10px;
    vertical-align: middle;
}
.ResultCellNoPadding {
    display: table-cell;
    font-weight: 700;
    vertical-align: middle;
}

.VarButtons {
    margin-bottom: 3px;
    position: relative;
    z-index: 1;
}

.VarButton {
    margin-right: 6px;
    cursor: pointer;
    padding: 5px;
    border-radius: 3px;
    background: var(--primary-color);
    color: #fff;
}

.ListItemValues {
    margin-top: 15px;
}
.ListItemValue {
    display: flex;
    align-items: center;
}
.ListItemValue svg {
    margin-left: auto;
    cursor: pointer;
}
.ListItemValue > div {
    margin: 5px 0 !important;
}

.AddUserButtonWrapper,
.AddAirportButtonWrapper {
    margin-bottom: 10px;
}

.ListItemValue .DeleteIcon {
    margin-right: 12px;
}

.VerticalSpacer {
    margin: 40px 0;
}
.VerticalSpacerHalf {
    margin: 20px 0;
}

.HorizontalSpacer {
    margin: 0 10px;
}
.HorizontalSpacerHalf {
    margin: 0 5px;
}

.AirportDetails {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.InputWrapper {
    margin-right: 20px;
    min-width: 300px;
}

.MapWrapper {
    height: 300px;
    width: 100%;
    margin-bottom: 20px;
    flex: 1;
}

.MapWrapper > div {
    height: 100%;
}

.PaddingBottom20 {
    padding-bottom: 20px;
}

.AirportLocation {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.LocationDetailsWrapper {
    display: flex;
    align-items: center;
}

.EditLocationWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.PopupRow {
    margin-bottom: 3px;
}

.PopupRow span:first-child {
    color: var(--primary-color);
    font-weight: 700;
    margin-right: 10px;
}

.PopupEditButton {
    display: flex;
    align-items: center;
    border-top: solid 1px;
    margin-top: 15px;
    padding-top: 15px;
}
.PopupButtonWrapper:first-child {
    margin-right: 10px;
}

.StylingItemRow {
    display: flex;
    align-items: center;
}
.StylingItemName {
    flex: 0 0 250px;
}

.FiltersSpace {
    background: var(--background-color);
    flex: 0 0 64px;
    display: flex;
    align-items: center;
}
