.NavigationItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    height: 100%;
}

@media (max-width: 1200px) {
    .NavigationItems {
        flex-direction: column;
        height: unset;
    }
}
