.Tooltip{
    position: absolute;
    right: 10px;
    top: 30px;
    width: 24px;
    height: 24px;
    background: var(--primary-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 10px;
    cursor: pointer;
}

