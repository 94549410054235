.PageTitle {
    display: flex;
    align-items: center;
    padding: 20px;
    background: #fff;
    border-bottom: solid 1px var(--background-color);
}

.PageContent {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.PageInner {
    flex: 1;
    padding: 20px;
    min-height: 100%;
    box-sizing: border-box;
}

.FiltersSpace {
    background: var(--background-color);
    flex: 0 0 64px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.FilterName {
    font-weight: 700;
    margin-right: 10px;
    color: var(--primary-color);
}

.FiltersSpace input {
    height: 0;
    background: #fff;
    text-align: center;
    border: solid 2px var(--secondary-color);
}

.FiltersSpace > div {
    margin: 0 !important;
}

:local(.FiltersSpace) :global(.react-datepicker__input-container .MuiFormControl-root) {
    margin: 0 !important;
}
