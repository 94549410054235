.PrimaryButton{
    text-transform: initial!important;
    font-weight: 400!important;
}
.PrimaryButton svg{
    width: 20px;
    height: 20px;
    margin-left: 15px;
    fill:#fff;
    color: #fff;
    font-size: 16px;
}

.PrimaryButton.DangerButton{
    background: var(--danger-color)!important;
}

.PrimaryButton svg:first-child{
    margin-left: 0;
    margin-right: 15px;
}

.PrimaryButton span{
    color:#fff;
}

.FullWidth{
    width:100%;
}

.PrimaryButton.IconButton{
    border-radius: 50%;
    width: 36px;
    height: 36px;
    min-width: unset;
}

.PrimaryButton.IconButton svg{
    margin:0;
    font-size: 16px;
}