.ScrollInner {
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
}

.PageTitle {
    display: flex;
    align-items: center;
    padding: 20px;
    background: #fff;
    border-bottom: solid 1px var(--background-color);
    min-height: 40px;
}

.PageContent {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.PageInner {
    flex: 1;
    min-height: 100%;
    box-sizing: border-box;
}

.Row {
    display: flex;
}

.Column {
    width: 48%;
}

.Separator {
    width: 4%;
}

.MarginBottom20 {
    margin-bottom: 20px;
}

.MarginBottom40 {
    margin-bottom: 40px;
}

.BlockItem {
    padding-bottom: 30px;
}

.GseFilesWrapper,
.GseImageWrapper {
    position: relative;
    margin-top: 23px;
    margin-bottom: 15px;
}

.GseImageWrapper.WithImage {
    max-width: 400px;
}

.GseImageWrapper img {
    width: 100%;
}

.DeleteImageWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    top: 0;
}

.DeleteImage {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
}

.DeleteImage svg {
    font-size: 20px;
    color: var(--danger-color);
}

.UploadFilesTitle {
    margin-bottom: 7px;
    font-size: var(--normal-font-size);
}

.InfoList {
    margin-top: 10px;
}

.InfoList li,
.InfoList > div {
    position: relative;
    line-height: 26px;
}

.InfoList li input[type='text'] {
    margin-right: 30px;
}

.InfoList li.MultipleInputs {
    display: flex;
    align-items: center;
}

.HorizontalSpacer {
    margin-right: 10px;
}

.ListTitle {
    color: var(--primary-color);
    font-weight: 700;
    margin-right: 10px;
}

.MapWrapper {
    height: calc(100% - 247px);
    width: 100%;
}

.MapWrapper > div {
    height: 100%;
}

.Flexed {
    display: flex;
}

.DatetimeWrapper {
    flex: 1;
}

.DatetimeWrapper > div {
    width: 100%;
}

.DatetimeWrapper:first-child {
    padding-right: 10px;
}

.DatetimeWrapper:last-child {
    padding-left: 10px;
}

.ButtonWrapper {
    margin-bottom: 20px;
}

.ConsumptionGroup {
    position: relative;
    width: 100%;
    max-width: 500px;
    border: solid 2px #e0e0e0;
    padding: 0 10px;
    margin-left: -10px;
}

.ConsumptionDiff {
    position: absolute;
    right: 10px;
    bottom: 0;
    color: var(--danger-color);
}

.BlockDivider {
    background: var(--background-color);
    padding: 20px;
    margin-left: -30px;
    width: calc(100% + 30px);
    margin-bottom: 30px;
}

.DatesWrapper {
    display: flex;
}

.ButtonsWrapper {
    display: flex;
    margin-left: 20px;
    margin-top: 0;
    width: 120px;
}

.ChartWrapper {
    width: 100%;
    height: 300px;
    margin-top: 30px;
}

.AlertIcons {
    display: flex;
    align-items: center;
    overflow: auto;
}

.AlertType {
    position: relative;
    flex: 0 0 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #18222c;
    margin-right: 20px;
    cursor: pointer;
}

.AlertType.Selected {
    background: var(--primary-font-color);
}

.AlertType svg {
    width: 40px;
    fill: var(--primary-font-color);
}

.AlertType:first-child svg {
    width: 80px;
}

.AlertType.Selected svg {
    fill: #fff;
}

.AlertType > span {
    position: absolute;
    bottom: 15px;
    color: #18222c;
    font-weight: 700;
}

.AlertType:nth-child(2) {
    border-color: var(--danger-color);
}
.AlertType:nth-child(3) {
    border-color: #cb9a13;
}
.AlertType:nth-child(4) {
    border-color: #1da140;
}

.AlertResults {
    margin-top: 30px;
}

.TableActionsWrapper svg {
    padding: 5px;
    cursor: pointer;
    margin-right: 5px;
}
.TableActionsWrapper svg:last-child {
    margin-right: 0;
}

.DeleteIcon {
    color: var(--danger-color);
}

.ActionButtonsWrapper {
    text-align: right;
    margin-left: auto;
    display: flex;
    align-items: center;
}
.ActionButtonsWrapper button {
    margin-right: 30px;
}

.PageTitle .CancelButton {
    margin-left: auto;
    cursor: pointer;
    background: var(--background-color);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PageTitle .CancelButton svg {
    font-size: 16px;
    color: var(--primary-color);
    transition: transform 0.2s ease-in;
}
.PageTitle .CancelButton:hover svg {
    transform: scale(1.1);
}

.EditRowsWrapper {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
    margin-bottom: 0;
}

.EditRow {
    display: flex;
    align-items: center;
    margin-bottom: -20px;
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
}

.UploadItemsWrapper {
    margin-bottom: 5px;
}

.FileItem {
    display: flex;
    align-items: center;
}

.FileActions {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.FileItem svg {
    padding: 5px;
    cursor: pointer;
    margin-left: 5px;
}

.FileProgressBarBackground {
    width: 100%;
    height: 3px;
    border-radius: 5px;
    background: #e6e6e6;
    position: relative;
}

.FileProgressBar {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 3px;
    border-radius: 3px;
    background: var(--secondary-color);
    transition: width 0.2s ease-in;
}

.FiltersSpace {
    background: var(--background-color);
    flex: 0 0 64px;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 1000px) {
    .Row {
        flex-direction: column;
    }
    .Column {
        width: 100%;
    }

    .DatetimeWrapper > form,
    .DatetimeWrapper > form > div {
        width: 100%;
    }

    .ButtonWrapper {
        margin-top: 20px;
    }

    .MapWrapper {
        min-height: 400px;
    }

    .ButtonsWrapper {
        margin-left: 0;
        margin-top: 30px;
        width: 100%;
    }
}
