.PageTitle {
    display: flex;
    align-items: center;
    padding: 20px;
    background: #fff;
    border-bottom: solid 1px var(--background-color);
    min-height: 40px;
}

.PageContent {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.PageInner {
    flex: 1;
    min-height: 100%;
    box-sizing: border-box;
}

.ScrollInner {
    padding: 20px;
    min-height: 100%;
    box-sizing: border-box;
}

.FiltersSpace {
    background: var(--background-color);
    flex: 0 0 64px;
    display: flex;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
}

.BoxWrapper {
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.2s ease-in;
}
.BoxWrapper:hover {
    background-color: var(--background-color);
}

.Boxes {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.Box {
    width: 120px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid 1px;
    padding: 20px;
}
.Box > svg {
    font-size: 50px;
    color: var(--primary-color);
    fill: var(--primary-color);
    flex: 1;
}
.Box > span {
    font-weight: 600;
    color: var(--primary-color);
    margin-top: auto;
}

.Box.TwoIcons > svg {
    font-size: 30px;
}

.PairedIcons > svg {
    font-size: 30px;
    color: var(--primary-color);
    fill: var(--primary-color);
    flex: 1;
}

.PairedIcons > svg:first-child {
    margin-right: 15px;
}
