.Legend {
    display: flex;
    flex-direction: column;
    padding: 6px 8px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    text-align: left;
    line-height: 18px;
}

.Legend h4 {
    margin: 0 0 5px;
    color: var(--primary-color);
}

.LegendItems{
    display: flex;
    flex-direction: column;
}

.LegendItem {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

.LegendIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    float: left;
    margin-right: 8px;
    border-radius: 50%;
}

.LegendIcon svg {
    width: 10px;
    fill:#fff;
}